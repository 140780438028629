var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
import MapboxLanguage from '@mapbox/mapbox-gl-language';
var $ = require("jquery");
const axios = require('axios');


if(document.getElementById('map')) {
	var base_url = 'https://' + location.hostname;
	var geoURL = base_url + "/feed/clubs.geojson";


/* ====================== MENU TOGGLE ======================  */
	mapboxgl.accessToken = 'pk.eyJ1IjoicGxhdGZvcm04MSIsImEiOiJjanJxNDdmNngxM2sxNDNwc2t3MWM3amtvIn0.OF9r8oKz5WK6kDkkc5xH1Q';
	var map = new mapboxgl.Map({
		container: 'map',
		style: 'mapbox://styles/platform81/cjscxum0k0lqf1fn1g4rrfbfe',
		// style: 'mapbox://styles/platform81/cjsbkilef16a01fns06qgy78l',
		center: [2.317600, 48.866500],
		zoom: 2
	});

	const language = new MapboxLanguage();
	map.addControl(language);

	map.addControl(new mapboxgl.NavigationControl({
		showCompass: false
	}), 'bottom-right');

	map.on('style.load', function(){
		var highCount = 75,
        	lowCount = 15;

		map.addSource('wolves-supporters', {
			type: "geojson",
			data: geoURL,
			cluster: true,
			clusterMaxZoom: 14, // Max zoom to cluster points on
			clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
		});

		map.addLayer({
			id: "clusters",
			type: "circle",
			source: "wolves-supporters",
			filter: ["has", "point_count"],
			paint: {
				"circle-color": [
					"step",
					["get", "point_count"],
					"#FDB913",
					100,
					"#f1f075",
					750,
					"#f28cb1"
				],
				"circle-radius": [
					"step",
					["get", "point_count"],
					20,
					100,
					30,
					750,
					40
				]
			}
		});

		map.addLayer({
			id: "cluster-count",
			type: "symbol",
			source: "wolves-supporters",
			filter: ["has", "point_count"],
			layout: {
				"text-field": "{point_count_abbreviated}",
				"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
				"text-size": 12
			}
		});

		map.loadImage( base_url + "/feed/map-marker.png", function(error, image) {
			if (error) throw error;
			map.addImage('marker', image);
			map.addLayer({
				id: "unclustered-point",
				type: "symbol",
				source: "wolves-supporters",
				filter: ["!", ["has", "point_count"]],
				layout: {
					"icon-image": "marker",
				},
			});
		});
	});

	map.on('click', 'clusters', function (e) {
		var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
		var clusterId = features[0].properties.cluster_id;
		map.getSource('wolves-supporters').getClusterExpansionZoom(clusterId, function (err, zoom) {
			if (err)
				return;

			map.easeTo({
				center: features[0].geometry.coordinates,
				zoom: zoom
			});
		});
	});

	map.on('mouseenter', 'clusters', function () {
		map.getCanvas().style.cursor = 'pointer';
	});

	map.on('mouseleave', 'clusters', function () {
		map.getCanvas().style.cursor = '';
	});

	map.on('click', 'unclustered-point', function(e) {
		var features = map.queryRenderedFeatures(e.point, { layers: ['unclustered-point'] });
		// var features = map.queryRenderedFeatures(e.point, {
	 //    	layers: ['wolves-supporters'] // replace this with the name of the layer
	 //  	});

		if (!features.length) {
	    	return;
	  	}

	  	var feature = features[0];
	  	var title = feature.properties.title;
	  	var newTitle = '';

	  	newTitle = title.replace(/ /g, "-").toLowerCase();
	  	newTitle = $.trim(newTitle);

	  	console.log(newTitle);

	  	map.flyTo({center: feature.geometry.coordinates, zoom: 12});
		$('.club-details .club').removeClass('club--active')
		$('.maps__results').removeClass('maps__results--active');

		if($('.club-details .club[data-related="'+newTitle+'"]').length >= 1 ) {
			$('.club-details .club[data-related="'+newTitle+'"]').addClass('club--active');
		} else {
			$('.club-details .club[data-related="no-club"]').find('.heading').text(title);
			$('.club-details .club[data-related="no-club"]').addClass('club--active');
		}

		$('.club-details').show();
	});

};

$(document).ready(function () {

	$('.maps input[name="location"]').on('input', function() {
		var inputValue = $(this).val();
		if(inputValue.length > 2) {
			$('.club-details .club').removeClass('club--active')
			$('.maps__results').addClass('maps__results--active');
			$('.maps__search').addClass('maps__search--active');

	        $.ajax({
	            type:"POST",
	            url:ajax_script.ajaxurl,
				data: {
					action : 'get_clubs',
					value : inputValue
				},
	            success:function(data){
				    // $('.maps__overview .maps__results').html('');
	                autoComplete(inputValue, data);
	            },
	            error:function(data){
	                console.log('error');
	            }
	        });
		} else {
			$('.maps__search').removeClass('maps__search--active');
			$('.maps__overview .maps__results').empty();
		}
	});

	$('.maps__search .form-group--close').on('click', function() {
		$('.maps input[name="location"]').val('');
		$('.maps__overview .maps__results').empty();
		map.flyTo({center: [2.317600, 48.866500], zoom: 2});

		$('.club-details .club').removeClass('club--active');
		$('.club-details').hide();
		$('.maps__results').addClass('maps__results--active');
	});

	function autoComplete(inputValue, data) {
		$.ajax({
			method:'get',
			url:'https://api.mapbox.com/geocoding/v5/mapbox.places/'+inputValue+'.json?access_token='+mapboxgl.accessToken,
			success:function(response){

				if(data == 0) {
					data = '';
				}

				$.each(response.features, function () {
					data += '<div class="result" data-lng="'+this.center[0]+'" data-lat="'+this.center[1]+'" > <div class="result__text">'+this.place_name+'</div></div>';
				});

				$('.maps__overview .maps__results').html(data);
			},
			error:function(data){
	         	console.log('error');
	        }
		})
	}


	// $(document).on("click", ".result", function() {
	// 	var dataLng = $(this).attr("data-lng");
	// 	var dataLat = $(this).attr("data-lat");
	// 	var related = $(this).attr("data-related");
	// 	var text = $(this).find('.result__text').text();

	// 	var coordinates = [dataLng, dataLat];
	// 	map.flyTo({center: coordinates, zoom: 8});

	// 	$('.maps__results').removeClass('maps__results--active');
	// 	$('.club-details .club').removeClass('club--active')

	// 	if($(this).hasClass('result--club')) {
	// 		$('.club-details .club[data-related="'+related+'"]').addClass('club--active');
	// 	} else {
	// 		$('.club-details .club[data-related="no-club"]').find('.heading').text(text);
	// 		$('.club-details .club[data-related="no-club"]').addClass('club--active');
	// 	}

	// 	$('.club-details').show();
	// })

	$(document).on("click", ".result", function() {
		var dataLng = parseFloat($(this).attr("data-lng"));
		var dataLat = parseFloat($(this).attr("data-lat"));
		var related = $(this).attr("data-related");
		var text = $(this).find('.result__text').text();
	
		var coordinates = [dataLng, dataLat];
		map.flyTo({center: coordinates, zoom: 8});
	
		$('.maps__results').removeClass('maps__results--active');
		$('.club-details .club').removeClass('club--active');
	
		if ($(this).hasClass('result--club')) {
			$('.club-details .club[data-related="'+related+'"]').addClass('club--active');
		} else {
			$('.spinner').show();
	
			$.ajax({
				method: 'post',
				url: ajax_script.ajaxurl,
				data: {
					action: 'get_nearest_clubs',
					lat: dataLat,
					lng: dataLng
				},
				success: function(response) {
					$('.spinner').hide();
	
					if (response.success && response.data.length > 0) {
						var nearestClub = response.data[0];
						var relatedClub = $('.club-details .club[data-related="'+nearestClub.related+'"]');
	
						if (relatedClub.length > 0) {
							relatedClub.addClass('club--active');
						} else {
							$('.club-details .club[data-related="no-club"]').find('.heading').text(nearestClub.title + " is the closest club.");
							$('.club-details .club[data-related="no-club"]').addClass('club--active');
						}
					} else {
						$('.club-details .club[data-related="no-club"]').find('.heading').text('No clubs within 30km');
						$('.club-details .club[data-related="no-club"]').addClass('club--active');
					}
	
					$('.club-details').show();
				},
				error: function() {
					$('.spinner').hide();
					console.log('An error occurred');
				}
			});
		}
	
		$('.club-details').show();
	});
	
	
	

	function getLocation() {
	    if (navigator.geolocation) {
	        navigator.geolocation.getCurrentPosition(success, error);
	    } else {
	        x.innerHTML = "Geolocation is not supported by this browser.";
	    }
	}

	function success(pos) {
		// console.log('success');
	  var crd = pos.coords;

	  // console.log('Your current position is:');
	  // console.log(`Latitude : ${crd.latitude}`);
	  // console.log(`Longitude: ${crd.longitude}`);

		var coordinates = [crd.longitude, crd.latitude];
		map.flyTo({center: coordinates, zoom: 7});
	}

	function error(err) {
	  console.warn(`ERROR(${err.code}): ${err.message}`);
	}

	if($('#map').length > 0 ) {
		getLocation();
	}
});
